import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('Affiliate', [
      'ActionSetAffiliates',
      'ActionSetAffiliateSelect',
      'ActionSetFilterAffiliate',
      'ActionSetDialogAffiliate',
      'ActionSetAffiliateBonus',
      'ActionSetFilterAffiliateBonus'
    ]),

    async getAffiliates(page, perPage) {
      let query;
      if (!this.filterAffiliate) query = '';
      else query = `${this.filterAffiliate}%26`;
      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26perPage=10';
      else query += perPage;
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=/api/get/affiliate/search?' + query
        );
        if (status === 200) {
          this.ActionSetAffiliates(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async createAffiliateBonus(formData) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.post(`/redirect`, {
          app: `conversorphp`,
          path: '/api/transaction/addBonusToTransaction/',
          params: `{"data":${JSON.stringify(formData)}}`
        });
        if (status === 200) {
          this.getAffiliateBonus();
          this.successNotify('Bônus adicionado com sucesso!');
          this.ActionSetDialogAffiliate(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getAffiliateBonus(page, perPage) {
      let query;
      if (!this.filterAffiliateBonus) query = '';
      else query = `${this.filterAffiliateBonus}`;
      if (!perPage) query += 'perPage%3D10';
      else query += perPage;
      if (!page) query += '%26page%3D1';
      else query += page;
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=/api/commission/bonusList/%3F' + query
        );
        if (status === 200) {
          this.ActionSetAffiliateBonus(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async editAffiliateBonus(id, formData) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.put(`/redirect`, {
          app: `conversorphp`,
          path: '/api/transaction/updateBonus/' + id,
          params: `{"data":${JSON.stringify(formData)}}`
        });
        if (status === 200) {
          this.getAffiliateBonus();
          this.successNotify('Bônus alterado com sucesso!');
          this.ActionSetDialogAffiliate(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async deleteAffiliateBonus(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.delete(
          '/redirect?app=CONVERSORPHP&path=/api/transaction/deleteBonus/' + id
        );
        if (status === 200) {
          this.ActionSetAffiliateBonus(data.data);
          this.ActionSetPagination(data);
          this.successNotify('Bônus removido com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          name: 'id',
          required: true,
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome',
          field: 'name',
          sortable: true
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status'
        },
        {
          align: 'center',
          name: 'comercial_manager_id',
          label: 'Comercial',
          field: 'comercial_manager_id'
        },
        {
          align: 'center',
          name: 'service_manager_id',
          label: 'Atendimento',
          field: 'service_manager_id'
        },
        {
          name: 'created_at',
          align: 'center',
          label: 'Data de cadastro',
          field: 'created_at'
        },
        {
          name: 'email',
          align: 'left',
          label: 'Email',
          field: 'email'
        },
        {
          name: 'action',
          align: 'center',
          label: 'Ações',
          field: 'id'
        }
      ];
    },
    ...mapGetters('Affiliate', [
      'affiliates',
      'affiliateSelect',
      'filterAffiliate',
      'dialogAffiliate',
      'affiliateBonus',
      'filterAffiliateBonus'
    ])
  }
};
